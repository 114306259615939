$lp_offer_color = #fbe401

.wa-offer__wrapper-dark
  background-color: #fff
  background-position: top center;
  background-repeat: no-repeat;

.wa-offer__wrapper-light
  background-color: #fff

.wa-offer__banner-wrap
  text-align:center;

.wa-offer__container
  width: $page_width
  margin: 0 auto;
  position relative
  z-index: 2
  .wa-offer__wrapper-dark &
    background-color: #fff
  +below($wide)
    width: 100%
    padding: 0 14px

.wa-offer__header
  font-family: $font_family_base
  font-weight: 900
  text-transform: uppercase;
  margin 40px 0
  font-size: 2.5rem
  text-align: center;
  line-height: 1
  span
    color: $lp_offer_color
  +below($tablet)
    font-size: 1.8rem

.wa-offer__header-light
  color: #fff

.wa-offer__header-dark
  color: #333

.wa-offer__intermingle
  margin: 70px 0 130px 0
  +below($tablet)
    margin: 0px 0 60px 0

.wa-offer__brands,
.wa-offer__categories
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

.wa-offer__brand,
.wa-offer__category
  text-align: center;
  width: 25%
  margin-bottom: 40px
  color: #333
  transition:all 0.3s ease;
  &:hover
    text-decoration: none;
    transform: scale(1.05)
  +below($desktop)
    width: calc(100%/3);
    padding: 15px
  +below($tablet)
    width: 50%;
    margin-bottom: 10px

.wa-offer__brand
  margin-bottom: 15px
  +below($tablet)
    margin-bottom: 0

.wa-offer__brand-banners
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 80px
  +below($tablet)
    margin-bottom: 30px

.wa-offer__brand-banner
  width: 49%
  margin-bottom: 30px
  transition:all 0.3s ease;
  &:nth-child(2n)
    margin-left: 2%
    +below($tablet)
      margin-left: 0
  &:hover
    text-decoration: none;
    opacity:0.8;
  +below($tablet)
    width: 100%;
  img
    width: 100%

.wa-offer__brand-banners-big
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

.wa-offer__brand-banner-big
  width: 70%
  margin-bottom: 30px
  transition:all 0.3s ease;
  &:hover
    text-decoration: none
    opacity:0.8;
  &:last-child
    margin-bottom: 0
  +below($tablet)
    width: 100%

.wa-offer__brand-header
  padding-top: 40px

.wa-offer__brand-banner-label,
.wa-offer__brand-banner-big-label
  text-align: center;
  font-size: 1.4rem
  color: #fff
  span
    color: $lp_offer_color
  +below($tablet)
    font-size: 1rem

.wa-offer__carousel-wrap-first
  margin-bottom: 60px

.wa-offer__usps
  display: flex
  justify-content: space-between;
  +below($mobile)
    flex-direction column
    align-items center

.wa-offer__usp
  display: flex
  align-items: center;
  justify-content: center;
  width: calc(100%/3);
  +below($mobile)
    width: 80%

.wa-offer__divider
  background-color: #fff
  svg
    height 100px
    fill: #000
    stroke: #000
    +below($tablet)
      height 30px
