.wa-bf__countdown-wrapper
  background-image: url(//images.wagcdn.com/f/landingpages/blackfriday2018/countdown/dk/top-bg.jpg), url(//images.wagcdn.com/f/landingpages/blackfriday2018/countdown/dk/bf-repeat-bg.jpg)
  background-size: contain;
  background-repeat: no-repeat, repeat;

.wa-bf__countdown-content
  pageWrapper()
  padding-bottom: 20px
  +below(600px)
    padding: 0

.wa-bf__header-box
  display: flex
  background-image: url(//images.wagcdn.com/f/landingpages/blackfriday2018/countdown/dk/top-banner-bg.jpg)
  background-size: cover;
  border: 10px solid #000
  +below(600px)
    border-width: 6px
    border-left: 0
    border-right: 0
  +below($desktop)
    flex-direction: column;
    justify-content: center;
    align-items: center;

.wa-bf__header-box-countdown-header
  text-transform: uppercase;
  font-size: 2rem
  color: #000
  margin: 20px 0
  text-align: center;
  +below($wide)
    font-size: 1.7rem

.wa-bf__countdown-box
  display: flex
  border: 10px solid #000
  margin-top: 36px
  justify-content: space-between;
  img
    vertical-align: top;
  +below($desktop)
    flex-direction: column;
  +below(600px)
    border-width: 6px
    border-left: 0
    border-right: 0

.newsletter-wrapper
  +below($desktop)
    max-width: 600px
    margin: auto
    margin-top: 36px

.wa-bf__box-newsletter
  display: flex
  flex-direction: column-reverse
  padding: 20px 37px
  width: 50%
  min-height: 500px
  background-repeat: no-repeat
  +below($desktop)
    width: 100%
    min-height: 620px
    padding: 20px

.wa-bf__box-facebook
  width: 50%
  background-color: #000
  +below($desktop)
    width: 100%
  a
    display flex
    align-items: center
    justify-content: center

.wa-bf__countdown-header
  font-size: 3rem
  text-align: center
  text-transform: uppercase;

.wa-bf__countdown-seo
  padding-bottom: 20px !important
  pageWrapper()