.wa-bf
    &__main-wrapper
        background-color: #000000
        color $action_text_color
    &__main-disclaimer-text
        pageWrapper()
        text-align: right 
    &__main-content
        h1,h2,h3
            color #ffffff
        a
            &:hover
                text-decoration: none
        pageWrapper()
        padding-bottom: 20px
        +below(600px)
            padding: 0
            width: 90%
        margin-top: -100px

    &__main-header-box-offer
        background-image: url(//images.wagcdn.com/f/landingpages/blackfriday2018/main/dk/shards.png);
        background-repeat: no-repeat;
        background-position: top;
        &-logo
            max-height 225px
    &__main-santander-wrapper
        margin-bottom: 35px
        margin-top: 70px

    &__main-header-box-wrapper
        background-image: url(//images.wagcdn.com/f/landingpages/blackfriday2018/main/dk/shatter-bg.png)
        background-repeat: no-repeat;
        background-position: top
        padding-bottom: 125px

    &__main-header-box
        pageWrapper()
        &-countdown-usps
            background: #000000
        &-logo
            display flex
            justify-content center
            align-items center
        display: flex
        border: 10px solid #ffffff
        +below(600px)
            border-width: 6px
            border-left: 0
            border-right: 0
        +below($desktop)
            flex-direction: column;
            justify-content: center;
            align-items: center;

        &-offer
            display: flex
            justify-content: space-between;
            flex-direction: column;
            img
                vertical-align: top;
            +below($desktop)
                flex-direction: column;
            +below(600px)
                border-width: 6px
                border-left: 0
                border-right: 0

    &__usp-wrapper
        display: flex
        justify-content: center
        border: 10px solid #ffffff
        img
            max-width 80%
        +below(600px)
            flex-direction: column
    &__main-brand-text
        color #ffffff
        background-color: $brand_color
        padding: 10px
        border-radius: 0
        text-align: center
        margin-top -20px
        position relative
        +below($tablet)
            margin: auto
            width 280px
            margin-top: -40px
        +below(620px)
            width: auto
    &__main-title
        font-size: 3rem
        text-align: center

    &__brands-pligt
      font-size: .8rem
