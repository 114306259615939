$bf_green = #83dd86

.wa-bf__wrapper-dark
  background-color: #000
  background-position: top center;
  background-repeat: no-repeat;

.wa-bf__video
  position: absolute;
  z-index: 1
  max-width: 100%
  +below($tablet)
    display: none

.wa-bf__wrapper-light
  background-color: #fff

.wa-bf__banner-wrap
  display: flex
  justify-content: center;
  position: relative;
  width: 100%
  margin: 0 auto;

.wa-bf__banner-img
  margin: auto;

.wa-bf__banner-splat
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 9%;
  height: 25%;
  left: 37%;
  top: 33%;
  svg
    box(100%)
    filter: drop-shadow( 0px 0px 15px #000 );
  +below($tablet)
    display: none

.wa-bf__container
  width: $page_width
  margin: 0 auto;
  position relative
  z-index: 2
  .wa-bf__wrapper-dark &
    background-color: #000
  +below($wide)
    width: 100%
    padding: 0 14px

.wa-bf__header
  font-family: $font_family_base
  font-weight: 900
  text-transform: uppercase;
  margin-bottom: 30px
  margin-top: 0;
  font-size: 2.1rem
  text-align: center;
  line-height: 1
  span
    color: $bf_green
  +below($tablet)
    font-size: 1.8rem

.wa-bf__header-light
  color: #fff

.wa-bf__header-dark
  color: #333

.wa-bf__intermingle
  margin: 70px 0 130px 0
  +below($tablet)
    margin: 0px 0 60px 0

.wa-bf__brands,
.wa-bf__categories
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 30px

.wa-bf__brand,
.wa-bf__category
  text-align: center;
  width: 25%
  margin-bottom: 40px
  color: #fff
  transition:all 0.3s ease;
  &:hover
    text-decoration: none;
    transform: scale(1.05)
  +below($desktop)
    width: calc(100%/3);
    padding: 15px
  +below($tablet)
    width: 50%;
    margin-bottom: 10px

.wa-bf__brand
  margin-bottom: 15px
  +below($tablet)
    margin-bottom: 0

.wa-bf__brand-banners
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 80px
  +below($tablet)
    margin-bottom: 30px

.wa-bf__brand-banner
  width: 49%
  margin-bottom: 30px
  transition:all 0.3s ease;
  &:nth-child(2n)
    margin-left: 2%
    +below($tablet)
      margin-left: 0
  &:hover
    text-decoration: none;
    opacity:0.8;
  +below($tablet)
    width: 100%;
  img
    width: 100%

.wa-bf__brand-banners-big
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

.wa-bf__brand-banner-big
  width: 70%
  margin-bottom: 30px
  transition:all 0.3s ease;
  &:hover
    text-decoration: none
    opacity:0.8;
  &:last-child
    margin-bottom: 0
  +below($tablet)
    width: 100%

.wa-bf__brand-header
  padding-top: 40px

.wa-bf__brand-banner-label,
.wa-bf__brand-banner-big-label
  text-align: right;
  font-size: 1.4rem
  color: #fff
  span
    color: $bf_green
  +below($tablet)
    font-size: 1rem

.wa-bf__carousel-wrap-first
  margin-bottom: 60px

.wa-bf__usps
  display: flex
  justify-content: space-between;
  padding: 140px 0
  +below($tablet)
    padding: 40px 0

.wa-bf__usp
  display: flex
  align-items: center;
  justify-content: center;
  width: calc(100%/3);
  +below($tablet)
    width: 100%

.wa-bf__divider
  background-color: #fff
  svg
    height 100px
    fill: #000
    stroke: #000
    +below($tablet)
      height 30px
