.wa-bf
  &__kickstart-disclaimer-text
    text-align: center
  &__kickstart-wrapper
    background-image: url(//images.wagcdn.com/f/landingpages/blackfriday2018/countdown/dk/top-bg.jpg), url(//images.wagcdn.com/f/landingpages/blackfriday2018/countdown/dk/bf-repeat-bg.jpg)
    background-size: contain;
    background-repeat: no-repeat, repeat;

  &__kickstart-content
    pageWrapper()
    padding-bottom: 20px
    +below(600px)
      padding: 0

  &__header-box
    display: flex
    background-image: url(//images.wagcdn.com/f/landingpages/blackfriday2018/countdown/dk/top-banner-bg.jpg)
    background-size: cover;
    border: 10px solid #000
    +below(600px)
      border-width: 6px
      border-left: 0
      border-right: 0
    +below($desktop)
      flex-direction: column;
      justify-content: center;
      align-items: center;

    &-countdown-header
      text-transform: uppercase;
      font-size: 2rem
      color: #000
      margin: 20px 0
      text-align: center;
      +below($wide)
        font-size: 1.7rem

  &__box
    display: flex
    border: 10px solid #000
    margin-top: 36px
    justify-content: space-between;
    img
      vertical-align: top;
    +below($desktop)
      flex-direction: column;
    +below(600px)
      border-width: 6px
      border-left: 0
      border-right: 0

  &__countdown-header
    font-size: 3rem
    text-align: center
    text-transform: uppercase;

  &__kickstart-header-label
    font-size: 3rem
    text-align: center
    margin-top: 15px
    margin-bottom: 0

  &__kickstart-grid
    .wag-col-1-4
      background: #000
      padding: 10px
      border-radius: 5px

  &__countdown-seo
    padding-bottom: 20px !important
    pageWrapper()

  &__kickstart-cat-text
    color #ffffff
    background-color: black
    text-align center
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
