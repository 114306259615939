.footer
  background-color rgba(84, 88, 90, 0.3)
  min-height 200px
  padding-top 25px

.footer__icons
  display flex
  justify-content center
  padding-bottom 25px
  .icon-wrap
    display flex
    flex-direction column
    align-items center
    margin-right 30px
    max-width 40px
    +below($mobile)
      margin-right 20px
    +above($desktop + 2) // +2 is done on purpose to hit screens from 1px above iPad landscape
      &:hover
        .icon-image
          background-color #555
        svg
          fill #fff
        .icon-text
          color #444
    &:last-child
      margin-right 0px
    &:hover,
    &:active,
    &:focus
      text-decoration none
  .icon-image
    circle(40px)
    background-color #fff
    display flex
    justify-content center
    align-items center
    margin-bottom 3px
    transition background .25s ease-out
    svg
      width 24px
      height 14px
      fill #959595
      transition fill .25s ease-out
  .icon-text
    font-size 0.625rem
    color #999
    transition color .25s ease-out

.footer__columns
  display flex
  justify-content center
  padding-bottom 30px
  +below($mobile)
    flex-direction column
  .column
    +above($tablet + 1)
      &.column-1
        margin-left 30px
      &.column-2
        margin 0px 60px 0px 80px
    +between($mobile + 1, $tablet)
      &.column-1
        margin-left 20px
      &.column-2
        margin 0px 20px 0px 55px
    +below($mobile)
      text-align center
      padding-bottom 30px
      &.column-3
        padding-bottom 0px
  .column-header
    font-weight bold
    font-size 0.875rem
    margin-bottom 5px
    +below($mobile)
      margin-bottom 5px
  ul
    list-style-type none
    padding 0
    margin 0
  a
    font-size 0.875rem
    color #333

.footer__company-info
  align-items center
  display flex
  justify-content center
  text-align center
  padding 20px 20px 25px
  border-top 1px solid #ccc
  color #999
  font-size 0.875rem
  +below($mobile)
    font-size 0.625rem
    display block

  a
    color #999
  img
    +below($mobile)
      width 100px
  .footer-text
     margin-right 15px
    +below($mobile)
      margin-right 0px
      display grid

.footer__chat
  cursor: pointer;
  &:hover
    text-decoration: underline

.footer__chat-text
  font-size: 0.875rem;

.footer__chat-online
  color: $success_color

.footer__chat-offline
  color: $danger_color

.footer__main-icons-partners
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  gap: 28px;

  img
    max-height: 24px
    +below($mobile)
      max-height: 20px;
  .co2
    max-height: 46px
    img
      box(100px, 46px)
      max-height: 46px
  +below($mobile)
    padding: 0 8px;
